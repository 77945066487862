import React from "react";
import tw from "twin.macro";
import styled from "styled-components";
import { Container, ContentWithPaddingXl } from "components/misc/Layouts.js";
import {
  SectionHeading,
  Subheading as SubheadingBase,
} from "components/misc/Headings";

const HeadingContainer = tw.div``;
const Heading = tw(SectionHeading)``;
const Subheading = tw(SubheadingBase)`text-center mb-3`;
const Description = tw.p`mt-4 text-sm md:text-base lg:text-lg font-medium leading-relaxed text-secondary-100 max-w-xl text-center`;

const Cards = tw.div`mt-16 flex flex-wrap flex-row justify-center sm:max-w-2xl lg:max-w-5xl mx-auto`;
const Card = tw.div`p-8 min-h-full bg-gray-200`;
const CardContainer = tw.div`w-full sm:w-1/2 lg:w-1/2 flex flex-col min-h-full p-2`;
const CardContent = styled.div`
  ${tw`flex flex-col mt-6`}
  .imageContainer {
    ${tw`text-center rounded-full pb-4`}
    img {
      ${tw`w-16 mx-auto`}
    }
  }
  .name {
    ${tw`mt-1 text-xl font-medium text-gray-900`}
  }
  .description {
    ${tw`mt-2 text-base font-normal text-gray-700`}
  }
`;
export default ({
  heading = "Meet These Fine Folks",
  subheading = "",
  description = "",
  cards = [],
}) => {
  return (
    <Container>
      <ContentWithPaddingXl>
        <HeadingContainer>
          {subheading && <Subheading>{subheading}</Subheading>}
          {heading && <Heading>{heading}</Heading>}
          {description && <Description>{description}</Description>}
        </HeadingContainer>
        <Cards>
          {cards.map((card, index) => (
            <CardContainer key={index}>
              <Card>
                <CardContent>
                  {card.subtitle ? <span className="gradient-text">{card.subtitle}</span> : null}
                  {card.image ? <span className="imageContainer">
                  <img src={card.image} alt={card.title} />
                </span> : null}
                  <span className="name">{card.title}</span>
                  <span className="description">{card.description}</span>
                </CardContent>
              </Card>
            </CardContainer>
          ))}
        </Cards>
      </ContentWithPaddingXl>
    </Container>
  );
};
