import React from "react";
import tw from "twin.macro";
import styled from "styled-components";
import {SectionHeading, Subheading as SubheadingBase} from "components/misc/Headings.js";

const Container = tw.div`relative`;
const TwoColumn = tw.div`flex flex-col md:flex-row justify-between max-w-screen-xl mx-auto py-20 md:py-24 items-center`;
const Column = tw.div`w-full max-w-md mx-auto md:max-w-none md:mx-0`;
const TextColumn = styled(Column)(props => [
  tw`md:w-6/12 mt-16 md:mt-0`,
  props.textOnLeft ? tw`md:mr-12 lg:mr-16 md:order-first` : tw`md:ml-12 lg:ml-16 md:order-last`
]);

const TextContent = tw.div`lg:py-8 text-center md:text-left`;

const Subheading = tw(SubheadingBase)`text-center md:text-left`;
const Heading = tw(
  SectionHeading
)`mt-4 font-black text-left text-3xl sm:text-4xl lg:text-5xl text-center md:text-left leading-tight`;
const Description = tw.p`mt-4 text-center md:text-left text-sm md:text-base lg:text-lg font-medium leading-relaxed text-secondary-100`;


export default ({
  leftSubheading = "Our Expertise",
  leftHeading = (
    <>
      Connecting Motion <span tw="text-primary-500">to Life</span>
    </>
  ),
  leftDescription = "",
  leftSubDescription = "",
  rightSubheading = "Our Expertise",
  rightHeading = (
    <>
      Connecting Motion <span tw="text-primary-500">to Life</span>
    </>
  ),
  rightDescription = "",
  rightSubDescription = "",
}) => {
  // The textOnLeft boolean prop can be used to display either the text on left or right side of the image.

  return (
    <Container>
      <TwoColumn>
        <TextColumn textOnLeft>
          <TextContent>
            <Subheading>{leftSubheading}</Subheading>
            <Heading>{leftHeading}</Heading>
            <Description>{leftDescription}</Description>
            {leftSubDescription ? <Description>{leftSubDescription}</Description> : null}
          </TextContent>
        </TextColumn>
        <TextColumn textOnLeft={false}>
          <TextContent>
            <Subheading>{rightSubheading}</Subheading>
            <Heading>{rightHeading}</Heading>
            <Description>{rightDescription}</Description>
            {rightSubDescription ? <Description>{rightSubDescription}</Description> : null}
          </TextContent>
        </TextColumn>
      </TwoColumn>
    </Container>
  );
};
