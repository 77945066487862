import React from "react";
import tw from "twin.macro";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import MainFeature from "components/features/TwoColWithButton.js";
import MainFeature2 from "components/features/TwoColWithTwoHorizontalFeaturesAndButton.js";
import GetStarted from "components/cta/GetStarted";

import UseCase1 from "images/usecases/use-case-1.jpg";
import UseCase2 from "images/usecases/use-case-2.jpg";
import UseCase3 from "images/usecases/use-case-3.png";
import UseCase4 from "images/usecases/use-case-4.jpg";
import UseCase5 from "images/usecases/use-case-5.jpg";
import UseCase6 from "images/usecases/use-case-6.jpg";
import UseCase7 from "images/usecases/use-case-7.jpg";
import UseCase8 from "images/usecases/use-case-8.jpg";
import UseCase9 from "images/usecases/use-case-9.jpg";
import UseCase10 from "images/usecases/use-case-10.jpg";
import UseCase11 from "images/usecases/use-case-11.jpg";
import UseCase12 from "images/usecases/use-case-12.jpg";
import UseCase13 from "images/usecases/use-case-13.png";
import Footer from "../components/footers/FiveColumnWithInputForm";
import TwoColWithTextsOnly from "../components/features/TwoColWithTextsOnly";
import Header from "../components/headers/light";

const HighlightedText = tw.span`text-primary-500`;


export default () => {
  return (
    <AnimationRevealPage>
        <Header roundedHeaderButton />
        <TwoColWithTextsOnly
        leftSubheading=""
        leftSubDescription=""
        leftHeading="Rehabilitation"
        leftDescription="In rehabilitation settings, MAI-Motion plays a crucial role in assessing patients’ functional movements after surgery or injury. It provides objective data on range of motion, joint stability, and muscle activation, enabling therapists to tailor rehabilitation programs and track progress effectively."
        rightSubDescription=""
        rightDescription="John, a patient recovering from knee surgery, under went rehabilitation supported by MAI-Motion. The module allowed his therapist to precisely monitor his knee joint movements, resulting in a customised rehabilitation plan that accelerated his recovery and restored his mobility."
        rightHeading=""
        rightSubheading="Case Study"
        />
      <MainFeature2
        subheading=""
        showImageOverlay
        heading={
          <>
            <HighlightedText>Orthopaedics</HighlightedText>
          </>
        }
        description="Orthopaedic surgeons rely on MAI-Motion to evaluate joint function and surgical outcomes. It assists in preoperative planning by simulating joint movements and predicting postoperative range of motion. This information guides surgical decision-making and improves patient outcomes."
        imageSrc={UseCase1}
        showDecoratorBlob={false}
      />

        <MainFeature
            subheading=""
            hideButton
            showImageOverlay
            imageSrc={UseCase2}
            imageBorder={true}
            textOnLeft={false}
            imageDecoratorBlob={true}
            heading={ <>
                <HighlightedText>Sports </HighlightedText> Medicine
            </>}
            description="MAI-Motion is utilised by sports medicine professionals to assess athletes’ biomechanics during training or competition. It helps identify movement patterns, muscle imbalances, and potential injury risks, allowing for personalised training programs and injury prevention strategies."
        />
        <MainFeature
            subheading=""
            showImageOverlay
            imageSrc={UseCase13}
            imageBorder={true}
            textOnLeft
            imageDecoratorBlob={true}
            heading={ <>
                <HighlightedText>Biomechanics </HighlightedText> Research
            </>}
            description="MAI-Motion is a valuable asset in biomechanics research, enabling scientists to study human movement in various contexts. Researchers can analyse gait patterns, joint kinetics, and muscle coordination, contributing to advancements in understanding musculoskeletal disorders and developing innovative treatment approaches."
        />
        <MainFeature
            hideButton
            subheading=""
            showImageOverlay
            imageSrc={UseCase4}
            imageBorder={true}
            imageDecoratorBlob={true}
            textOnLeft={false}
            heading={ <>
                <HighlightedText>Telehealth  </HighlightedText> Physiotherapy
            </>}
            description= "MAI-Motion enables remote physiotherapy sessions by capturing patients’ motion data through video analysis. Patients can perform exercises at home while their movements are monitored in real-time by their physiotherapist. This remote monitoring ensures proper form and technique, allowing for accurate assessment and adjustment of the exercise program."
        />

        <TwoColWithTextsOnly
            leftSubheading=""
            leftSubDescription=""
            leftHeading="Postoperative Rehabilitation"
            leftDescription="After surgery, patients can receive remote physiotherapy sessions supported by MAI-Motion. By capturing and analysing their motion data,
physiotherapists can monitor their progress, assess range of motion, and guide them through tailored exercises. This approach allows for efficient and convenient rehabilitation while minimising the need for in-person visits."
            rightSubDescription=""
            rightDescription="David underwent a shoulder surgery and engaged in remote physiotherapy using MAI-Motion. His physiotherapist monitored his exercises and provided feedback remotely. With regular progress assessments, David successfully regained strength and mobility in his shoulder without needing frequent clinic visits."
            rightHeading=""
            rightSubheading="Case Study"
        />

        <MainFeature
            hideButton
            subheading=""
            showImageOverlay
            imageSrc={UseCase5}
            imageBorder={true}
            textOnLeft
            imageDecoratorBlob={true}
            heading={ <>
                <HighlightedText>Home Exercise </HighlightedText> Programs
            </>}
            description="MAI-Motion is utilised in designing and monitoring home exercise programs for patients who require ongoing rehabilitation. Physiotherapists create personalised exercise plans and provide instructional videos. Patients perform the exercises while recording their movements with MAI-Motion, ensuring proper execution and progress tracking."
        />
        <TwoColWithTextsOnly
            leftSubheading=""
            leftSubDescription=""
            leftHeading="Remote Monitoring for Chronic Conditions"
            leftDescription="MAI-Motion allows for remote monitoring of patients with chronic musculoskeletal conditions. Physiotherapists can regularly assess their functional movements and provide ongoing guidance and support. This remote monitoring approach improves patient compliance, reduces the need for frequent clinic visits, and enhances long-term management of chronic conditions."
            rightSubDescription="These examples demonstrate how MAI-Motion facilitates remote physiotherapy, bringing the benefits of personalised exercise programs, progress tracking, and ongoing guidance to patients’homes. Through video analysis and real-time monitoring,physiotherapists can effectively assess and guide patients, promoting recovery, and ensuring continuity of care regardless of geographical distance."
            rightDescription="Sarah, who suffers from chronic lower back pain, participates in a remote physiotherapy program using MAI-Motion. Her physiotherapist remotely monitors her movements, provides exercise modifications, and offers continuous support. Sarah experiences improved pain management and enhanced functionality, even from the comfort of her own home."
            rightHeading=""
            rightSubheading="Case Study"
        />
        <MainFeature
            subheading="Testimonial"
            showImageOverlay
            imageSrc={UseCase3}
            imageBorder={true}
            imageDecoratorBlob={true}
            textOnLeft={false}
            heading={ <>
                Initial <HighlightedText> Musculoskeletal </HighlightedText> Screening
            </>}
            hideButton
            subdescription="“As a fitness trainer, I use MAI-Motion as a quick assessment tool for my clients. It helps me identify any movement limitations or asymmetries, allowing me to tailor their training programs and address any potential issues before they escalate.”"
            description= "MAI-Motion can be used as a quick assessment tool for initial screenings invarious settings, such as clinics, sports facilities, or workplace healthprograms. By analysing a person’s motion data, MAI-Motion providesvaluable insights into their musculoskeletal condition, identifying anypotential areas of concern or imbalances."
        />
        <MainFeature
            hideButton
            subheading=""
            showImageOverlay
            imageSrc={UseCase6}
            imageBorder={true}
            imageDecoratorBlob={true}
            textOnLeft
            heading={ <>
                <HighlightedText> Self-Assessment </HighlightedText> Home
            </>}
            subdescription=""
            description= "Individuals can utilise MAI-Motion for self-assessment at home, providing them with peace of mind and a better understanding of their musculoskeletal health. By following the guided instructions and recording their movements, they can receive instant feedback on their range of motion, posture, and overall movement quality."
        />
        <MainFeature
            hideButton
            subheading=""
            showImageOverlay
            imageSrc={UseCase7}
            imageBorder={true}
            imageDecoratorBlob={true}
            textOnLeft={false}
            heading={ <>
                <HighlightedText> Quick Evaluation </HighlightedText> in Sports
            </>}
            subdescription=""
            description= "MAI-Motion is a valuable tool in sports settings, where quick assessments of athletes’ movements are essential. Coaches and trainers can use MAI-Motion to evaluate athletes’ performance, identify areas for improvement, and make data-driven decisions for training and injury prevention strategies."
        />


        <MainFeature
            hideButton
            subheading=""
            showImageOverlay
            imageSrc={UseCase8}
            imageBorder={true}
            imageDecoratorBlob={true}
            textOnLeft
            heading={ <>
                Objective  <HighlightedText> Assessment </HighlightedText>
            </>}
            subdescription=""
            description= "MAI-Motion provides an objective and quantifiable assessment of a patient’s musculoskeletal condition, making it an ideal tool for medical legal reporting. By analysing the patient’s motion data, MAI-Motion can provide comprehensive information on range of motion, joint stability, and movement patterns, which can be valuable in legal cases involving personal injury, workplace accidents, or medical negligence."
        />
        <MainFeature
            hideButton
            subheading=""
            showImageOverlay
            imageSrc={UseCase9}
            imageBorder={true}
            imageDecoratorBlob={true}
            textOnLeft={false}
            heading={ <>
                Pre- and Post-Injury <HighlightedText> Comparisons </HighlightedText>
            </>}
            subdescription=""
            description= "MAI-Motion allows for pre- and post-injury comparisons, enabling a clear understanding of the impact of an accident or incident on an individual’s musculoskeletal health. By comparing motion data before and after the incident, MAI-Motion provides a visual representation of any changes or limitations, aiding in the assessment of damages and potential long-term implications."
        />

        <MainFeature
            hideButton
            subheading=""
            showImageOverlay
            imageSrc={UseCase10}
            imageBorder={true}
            imageDecoratorBlob={true}
            textOnLeft
            heading={ <>
                Documentation of  <HighlightedText> Functional </HighlightedText> Limitations
            </>}
            subdescription=""
            description= "MAI-Motion can help document and quantify functional limitations resulting from an injury or medical condition. By analysing the patient’s movements, MAI-Motion provides a comprehensive assessment of their ability to perform specific tasks or activities, which is crucial in determining the extent of their functional impairments and their impact on daily life."
        />
        <MainFeature
            hideButton
            subheading=""
            showImageOverlay
            imageSrc={UseCase11}
            imageBorder={true}
            imageDecoratorBlob={true}
            textOnLeft={false}
            heading={ <>
                Comparison to Normal <HighlightedText> Range </HighlightedText>
            </>}
            subdescription=""
            description= "MAI-Motion allows for a comparison of a patient’s motion data to established normal ranges, providing a reference point for assessing the extent of their impairment or disability. This comparison helps provide an objective assessment of the patient’s condition and can be crucial in determining the impact of an injury on their ability to perform daily activities or return to work."
        />

        <MainFeature
            hideButton
            subheading=""
            showImageOverlay
            imageSrc={UseCase12}
            imageBorder={true}
            imageDecoratorBlob={true}
            textOnLeft
            heading=""
            subdescription="Whether you’re a healthcare professional, athlete, or individual seeking better musculoskeletal health, MAI-Motion can transform the way you understand and manage motion-related conditions. Take the next step towards a healthier future and explore the possibilities of MAI-Motion today. Contact us to learn more and discover how MAI-Motion can benefit you or your organisation."
            description= "MAI-Motion is a groundbreaking technology that revolutionises musculoskeletal motion analysis. Its advanced features, such as 3D mesh technology, integration with 3D volumetric MRI, and AI-driven decision support, make it a powerful tool in various clinical settings. From physiotherapy and rehabilitation to sports performance and occupational health, MAI-Motion offers precise assessments, personalised treatments, and improved patient outcomes. "
        />

        <GetStarted/>
      <Footer/>
    </AnimationRevealPage>
  );
}
