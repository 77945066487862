import React from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import {useParams} from "react-router-dom";
import {Container} from "components/misc/Layouts";
import tw from "twin.macro";
import Header from "components/headers/light.js";
import Footer from "components/footers/FiveColumnWithInputForm.js";
import {SectionHeading} from "components/misc/Headings";

import blogs from "../blogList.json"
import styled from "styled-components";
import {Helmet} from "react-helmet";

const ContentWithPaddingLg = tw.div`max-w-screen-lg mx-auto py-20 lg:py-24`;
const HeadingRow = tw.div`flex`;
const Heading = tw(SectionHeading)`text-gray-900`;
const Description = styled.div`
${tw`mt-6 text-left text-sm md:text-base lg:text-lg font-medium leading-relaxed text-secondary-100`}
    p{
    font-size: 1rem;
    }
    li{
    font-size: 1rem;
    }
    h2{
    font-size: 1.25rem;
    }
    ol, ul{
       padding-top: 10px;
    }
    li span{
       font-size: 1.125rem;
    }
`;
const CreationDate = tw.div`mt-4 uppercase text-right text-gray-600 italic font-semibold text-base`;
const ImageOverlay = tw.div`w-full absolute right-0 bottom-0 h-full bg-primary-500 opacity-25`;

export default () => {

    const {slug} = useParams();

    const post = blogs.find(a => a.slug === slug);

    if (!post) {
        return <HeadingRow>
            <Heading>404 Blog not found"</Heading>
        </HeadingRow>
    }
    return (
        <>
            <Helmet>
                <title>{post.title}</title>
                <meta name="description" content={post.shortDescription}/>
                <meta property="og:title" content={post.title}/>
                <meta property="og:description" content={post.title}/>
                <meta name="twitter:title" content={post.title}/>
                <meta name="twitter:description" content={post.title}/>
            </Helmet>
            <AnimationRevealPage>
                <Header/>
                <Container>
                    <ContentWithPaddingLg>
                        <HeadingRow>
                            <Heading>{post.title}</Heading>
                        </HeadingRow>
                        <CreationDate>
                            Date Created: {new Date(post.date).toLocaleDateString("en-GB")}
                        </CreationDate>
                        <div style={{position: "relative"}}>
                            <ImageOverlay/>
                            <img src={post.image} style={{margin: "30px auto", mixBlendMode:"color-burn"}} alt={post.title}/>
                        </div>
                        <Description>
                            <div dangerouslySetInnerHTML={{__html: post.html}}/>
                        </Description>
                    </ContentWithPaddingLg>
                </Container>
                <Footer/>
            </AnimationRevealPage>
        </>

    );
};