import React from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import Header from "components/headers/light.js";
import Pricing from "components/pricing/TwoPlansWithDurationSwitcher.js";
import Testimonial from "components/testimonials/ThreeColumnWithProfileImage.js";
import Footer from "components/footers/FiveColumnWithInputForm.js";
import GetStarted from "../components/cta/GetStarted";

export default () => {
    return (
        <AnimationRevealPage>
            <Header/>
            <Pricing
                description="Find the perfect plan for your needs by comparing the features included in each. Whether you're an individual patient, a doctor managing a practice, or an administrator overseeing a clinic, we have tailored plans to suit your requirements."/>
            <Testimonial
                heading="Our Paying Customers"
                testimonials={[
                    {
                        imageSrc:
                            "https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=3.25&w=512&h=512&q=80",
                        quote:
                            "Using the MAI Motion App has been truly life-changing. The integration of advanced 3D mesh technology with 3D volumetric MRI provided me with unprecedented insights into my hip motion. The experience with the MAI Motion App throughout my recovery was phenomenal, and the care provided was exceptional. I can't thank the MAI Motion team enough for their outstanding support and expertise.",
                        customerName: "Charlotte Hale"
                    },
                    {
                        imageSrc:
                            "https://images.unsplash.com/photo-1531427186611-ecfd6d936c79?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2.25&w=512&h=512&q=80",
                        quote:
                            "The MAI Motion App has transformed my recovery journey. By integrating advanced 3D mesh technology with 3D volumetric MRI, it gave me incredible insights into my knee motion. The entire experience with the app was seamless, and the care I received was outstanding. I'm incredibly grateful to the MAI Motion team for their exceptional support and innovative approach.",
                        customerName: "Adam Cuppy"
                    },
                    {
                        imageSrc:
                            "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ4YreOWfDX3kK-QLAbAL4ufCPc84ol2MA8Xg&s",
                        quote:
                            "The MAI Motion App has been a game-changer for my recovery. Using cutting-edge 3D mesh technology alongside 3D volumetric MRI, it provided me with unparalleled insights into my knee movement. The app's user experience was flawless, and the care and attention from the MAI Motion team were exceptional. I'm truly thankful for their innovative solutions and dedicated support.",
                        customerName: "Bethan Watson"
                    },
                ]}
            />
            <GetStarted/>
            <Footer/>
        </AnimationRevealPage>
    );
};
