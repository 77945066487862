import React from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import tw from "twin.macro";
import Header from "components/headers/light.js";
import Footer from "components/footers/FiveColumnWithInputForm.js";
import MainFeature1 from "components/features/TwoColWithButton.js";
import Features from "components/features/ThreeColSimple.js";
import TeamCardGrid from "components/cards/ProfileThreeColGrid.js";

import OurTeam from "images/team/our-team.png";
import AboutUseImage1 from "images/team/about-us-image.png";
import Layer_1 from "images/team/Layer_1.png";
import Layer_11 from "images/team/Layer_1-1.png";
import Layer_12 from "images/team/Layer_1-2.png";
import Layer_13 from "images/team/Layer_1-3.png";
import WhyWeDeveloped from "images/team/why-we-developed.png";
import GetStarted from "../components/cta/GetStarted";
import MainFeature from "../components/features/TwoColWithButton";
import Logo from "../images/full-logo.png";
import HeroBgImage from "../images/hero-bg-img.png";

const Subheading = tw.span`uppercase tracking-wider text-sm`;
const HighlightedText = tw.span`text-primary-500`;

export default () => {
    return (
        <AnimationRevealPage>
            <Header/>
            <MainFeature1
                subheading={""}
                description="At MAI-Motion, we’re pioneering a revolutionary approach to understanding human movement, bringing together a unique blend of expertise across medical, technological, and scientific disciplines. Our team comprises top orthopaedic consultant surgeons, leadingcomputer vision scientists, and acclaimed academics in biomechanics."
                buttonRounded={false}
                hideButton
                primaryButtonText="See Portfolio"
                imageSrc={AboutUseImage1}
            />
            <MainFeature
                subheading={""}
                heading={
                    <>
                        <HighlightedText>Our</HighlightedText> Vision
                    </>
                }
                imageSrc={Logo}
                description="We believe in a world where motion analysis goes beyond the surface, capturing not just visible movements, but also the intricate interplay of anatomical structures beneath. Ourgoal is to redefine motion analysis, delivering precise, comprehensive, and clinically relevantinsights to enhance patient outcomes in the field of musculoskeletal health."
                hideButton
                bgImage={HeroBgImage}
                textOnLeft={false}
            />
            <MainFeature1
                subheading={""}
                heading={
                    <>
                        <HighlightedText>Our</HighlightedText> Team
                    </>
                }
                buttonRounded={false}
                hideButton
                description="Our multidisciplinary team brings together a diverse array of experts. Each member plays apivotal role, contributing their unique knowledge and skills to the development of MAI-Motion."
                subdescription="Our orthopaedic surgeons provide a deep understanding of musculoskeletal conditions andthe clinical needs of patients and healthcare professionals. Our computer vision scientistsshape the advanced algorithms and artificial intelligence behind our innovative technology.Our biomechanics academics ensure the rigorous scientific underpinning of our approach,contributing a wealth of research and expertise in the field of human movement."
                imageSrc={OurTeam}
                textOnLeft
                showImageOverlay
            />
            <Features
                subheading=""
                heading="Our Values"
                description=""
                cards={[
                    {
                        imageSrc: Layer_13,
                        title: "Vision Statement",
                        description: "Becoming the global leader in advanced motion analysis, driving innovation in musculoskeletal healthcare."
                    },
                    {
                        imageSrc: Layer_12,
                        title: "Mission Statement",
                        description: "Revolutionising musculoskeletal health through advanced AI, 3D motion analysis, and 3Dvolumetric MRI."
                    },
                    {
                        imageSrc: Layer_11,
                        title: "Aim Statement",
                        description: "Striving for excellence, fostering collaborations, and ensuring user-centric innovation in everything we do."
                    },
                    {
                        imageSrc: Layer_1,
                        title: "Strategy statement",
                        description: "To revolutionise musculoskeletal" +
                            "healthcare through the integration of advanced algorithms, and deep learning techniques, providing personalised and precise analysis to improved patient outcomes."
                    },
                ]}
                linkText=""
            />
            <TeamCardGrid
                subheading={<Subheading>Our Team</Subheading>}
            />
            <MainFeature1
                subheading={""}
                heading={
                    <>
                        <span tw="text-primary-500">Why We Developed</span> MAI-Motion
                    </>
                }
                hideButton
                description="We developed MAI-Motion to bridge a gap we saw in the field of musculoskeletal health: theneed for a holistic understanding of human movement. Traditional motion analysis toolssimply couldn’t provide the detailed, accurate insights we knew were possible.By integrating advanced 3D mesh technology with 3D volumetric MRI object detection,we’ve created a solution that delivers unprecedented insights into human movement and itsunderlying anatomical structures. Our technology is set to transform the diagnosis andtreatment of musculoskeletal conditions, aiding in everything from injury prevention tosurgical planning and rehabilitation."
                subdescription="We are excited about the possibilities MAI-Motion offers to healthcare professionals,researchers, athletes, and anyone seeking to understand and optimise their movement. Webelieve our technology has the potential to drive major advancements in the field ofmusculoskeletal health, and we’re proud to be leading the way."
                imageSrc={WhyWeDeveloped}
                textOnLeft
            />
            <MainFeature1
                subheading={""}
                heading={
                    <>
                        <span tw="text-primary-500">Supported by</span> Innovate UK KTP Project
                    </>
                }
                hideButton
                description="MAI-Motion is proud to have received support and funding from Innovate UK’s KnowledgeTransfer Partnership (KTP) project. This prestigious collaboration has allowed us to leveragecutting-edge research and expertise from leading academic institutions, including the University of Lincoln."
                subdescription="Through the KTP project, we have been able to translate scientificadvancements into practical solutions, enabling us to develop MAI-Motion and revolutionisethe field of musculoskeletal motion analysis. We are grateful for the support and recognitionfrom Innovate UK, which has been instrumental in our journey to create a clinically relevantand user-friendly technology."
                imageSrc="https://images.unsplash.com/3/doctype-hi-res.jpg?ixlib=rb-1.2.1&auto=format&fit=crop&w=768&q=80"
                textOnLeft={false}
            />
            <GetStarted/>

            <Footer/>
        </AnimationRevealPage>
    );
};
