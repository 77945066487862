import React from "react";
import tw from "twin.macro";

import LogoImage from "../../images/mai-logo.png";
import SubscribeForm from "../forms/SubscribeForm";
/*import { ReactComponent as FacebookIcon } from "images/facebook-icon.svg";
import { ReactComponent as TwitterIcon } from "images/twitter-icon.svg";
import { ReactComponent as YoutubeIcon } from "images/youtube-icon.svg";*/

const Container = tw.div`relative bg-gray-200 text-gray-700 -mb-8 -mx-8 px-8 py-20 lg:py-24`;
const Content = tw.div`max-w-screen-xl mx-auto relative z-10`;
const SixColumns = tw.div`flex flex-wrap sm:text-left justify-between sm:justify-start md:justify-between -mt-12`;

const Column = tw.div`px-2 sm:px-0 sm:w-1/4 md:w-auto mt-12`;

const ColumnHeading = tw.h5`uppercase font-bold`;

const LinkList = tw.ul`mt-6 text-sm font-medium`;
const LinkListItem = tw.li`mt-3`;
const Link = tw.a`border-b-2 border-transparent hocus:border-gray-700 pb-1 transition duration-300`;

const SubscribeNewsletterColumn = tw(Column)`text-center lg:text-left w-full! lg:w-auto! mt-20 lg:mt-12`;
const SubscribeNewsletterContainer = tw.div`max-w-sm mx-auto lg:mx-0 `;
const SubscribeText = tw.p`mt-2 lg:mt-6 text-sm font-medium text-gray-600`;
const Divider = tw.div`my-16 border-b-2 border-gray-300 w-full`;

const ThreeColRow = tw.div`flex flex-col md:flex-row items-center justify-between`;
const HighlightedText = tw.span`text-secondary-500`;

const LogoContainer = tw.div`flex items-center justify-center md:justify-start`;
const LogoImg = tw.img`w-8`;
const LogoText = tw.h5`ml-2 text-xl font-black tracking-wider text-gray-800`;

const CopywrightNotice = tw.p`text-center text-sm sm:text-base mt-8 md:mt-0 font-medium text-gray-500`;

/*const SocialLinksContainer = tw.div`mt-8 md:mt-0 flex`;
const SocialLink = styled.a`
  ${tw`cursor-pointer p-2 rounded-full bg-gray-900 text-gray-100 hover:bg-gray-700 transition duration-300 mr-4 last:mr-0`}
  svg {
    ${tw`w-4 h-4`}
  }
`;*/

export default () => {
  return (
    <Container>
      <Content>
        <SixColumns>
          <Column>
            <ColumnHeading>Main</ColumnHeading>
            <LinkList>
              <LinkListItem>
                <Link href="/about-us">About Us</Link>
              </LinkListItem>
              <LinkListItem>
                <Link href="/blogs">Blog</Link>
              </LinkListItem>
              <LinkListItem>
                <Link href="/pricing">Pricing</Link>
              </LinkListItem>
              <LinkListItem>
                <Link href="/use-cases">Use Cases</Link>
              </LinkListItem>
              <LinkListItem>
                <Link href="/contact-us">Contact Us</Link>
              </LinkListItem>
              <LinkListItem>
                <Link href="/faqs">FAQs</Link>
              </LinkListItem>
            </LinkList>
          </Column>
          <Column>
            <ColumnHeading>Product</ColumnHeading>
            <LinkList>
              <LinkListItem>
                <Link href="https://app.maimotion.com">MAI Motion</Link>
              </LinkListItem>
            </LinkList>
          </Column>
          <Column>
            <ColumnHeading>Legal</ColumnHeading>
            <LinkList>
              <LinkListItem>
                <Link href="https://mskdoctors.com/cookie">Cookie Policy</Link>
              </LinkListItem>
              <LinkListItem>
                <Link href="https://mskdoctors.com/privacy">Privacy Policy</Link>
              </LinkListItem>
              <LinkListItem>
                <Link href="https://mskdoctors.com/tos">Terms of Service</Link>
              </LinkListItem>
            </LinkList>
          </Column>
          <Column>
            <ColumnHeading>Office</ColumnHeading>
            <LinkList>
              <LinkListItem>
               <Link href="https://www.google.com/maps/search/?api=1&query=MSK+Doctors+The+Keep+Clinic+The+Old+Barracks+Sandon+Road+Grantham+NG31+9AS+United+Kingdom" target="_blank">
                 MAI Motion,
                 <br />
                 The Keep Clinic, The Old Barracks,
                 <br />
                 Sandon Road, Grantham,
                 <br />
                 NG31 9AS, United Kingdom
               </Link>
              </LinkListItem>
              <LinkListItem>
                <Link href="mailto:support@maimotion.com"><HighlightedText>Email: </HighlightedText> support@maimotion.com</Link>
              </LinkListItem>
              <LinkListItem>
                <Link href="tel:03300010048"><HighlightedText>Phone: </HighlightedText> 03300010048</Link>
              </LinkListItem>
            </LinkList>
          </Column>

          <SubscribeNewsletterColumn>
            <SubscribeNewsletterContainer>
              <ColumnHeading>Subscribe to our Newsletter</ColumnHeading>
              <SubscribeText>
                We deliver high quality blog posts written by professionals weekly. And we promise no spam.
              </SubscribeText>
              <SubscribeForm />
            </SubscribeNewsletterContainer>
          </SubscribeNewsletterColumn>
        </SixColumns>
        <Divider />
        <ThreeColRow>
          <LogoContainer>
            <LogoImg alt="MAI Motion" src={LogoImage} />
            <LogoText>MAI Motion</LogoText>
          </LogoContainer>
          <CopywrightNotice>&copy; 2024 Mai Motion. All Rights Reserved.</CopywrightNotice>
          {/*<SocialLinksContainer>
            <SocialLink href="https://facebook.com">
              <FacebookIcon />
            </SocialLink>
            <SocialLink href="https://twitter.com">
              <TwitterIcon />
            </SocialLink>
            <SocialLink href="https://youtube.com">
              <YoutubeIcon />
            </SocialLink>
          </SocialLinksContainer>*/}
        </ThreeColRow>
      </Content>
    </Container>
  );
};
