import React from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import FAQ from "components/faqs/SingleCol.js";
import GetStarted from "components/cta/GetStarted";
import Footer from "../components/footers/FiveColumnWithInputForm";
import Header from "../components/headers/light";

export default () => {
  return (
    <AnimationRevealPage>
        <Header roundedHeaderButton />
        <FAQ />
      <GetStarted/>
        <Footer/>
    </AnimationRevealPage>
  );
}
