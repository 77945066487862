import React from "react";
import GlobalStyles from 'styles/GlobalStyles';
import {Helmet} from 'react-helmet';

import {BrowserRouter as Router, Route, Routes} from "react-router-dom";
import AboutUs from "./pages/AboutUs";
import Blogs from "./pages/Blogs";
import BlogViewPage from "./pages/BlogViewPage";
import LandingPage from "./LandingPage";
import Pricing from "./pages/Pricing";
import ContactUs from "./pages/ContactUs";
import FAQs from "./pages/FAQs";
import UseCases from "./pages/UseCases";

export default function App() {
  // If you want to disable the animation just use the disabled `prop` like below on your page's component
  // return <AnimationRevealPage disabled>xxxxxxxxxx</AnimationRevealPage>;
  const routes = [
    {path: "/about-us", component: AboutUs, title: "Mai Motion - At MAI-Motion, we’re pioneering a revolutionary approach to understanding human movement, bringing together a unique blend of expertise across medical, technological, and scientific disciplines."},
    {path: "/contact-us", component: ContactUs, title: "Mai Motion - Contact Us"},
    {path: "/blogs/:slug", component: BlogViewPage},
    {path: "/blogs", component: Blogs, title: "Mai Motion - A Systematic Approach to Regeneration"},
    {path: "/pricing", component: Pricing, title: "Mai Motion - Find the perfect plan for your needs by comparing the features included in each. Whether you're an individual patient, a doctor managing a practice, or an administrator overseeing a clinic, we have tailored plans to suit your requirements."},
    {path: "/faqs", component: FAQs, title: "Mai Motion - You have Questions ? And we have got answers to all of them."},
    {path: "/use-cases", component: UseCases, title: "Mai Motion - In rehabilitation settings, MAI-Motion plays a crucial role in assessing patients’ functional movements after surgery or injury. It provides objective data on range of motion, joint stability, and muscle activation, enabling therapists to tailor rehabilitation programs and track progress effectively."},
    {path: "/", component: LandingPage, title: "MAI Motion - Capturing Movement, Enhancing Health"},
  ];

  return (
    <>
      <GlobalStyles/>
      <Router>
        <Routes>
          {routes.map(({path, component: Component, title}, index) => (
              <Route key={index} exact path={path} element={
                <>
                  {title ?<Helmet>
                    <title>{title}</title>
                    <link rel="canonical" href={`https://maimotion.com${path}`}/>
                    <meta name="description" content={title}/>
                    <meta property="og:title" content={title}/>
                    <meta property="og:description" content={title}/>
                    <meta name="twitter:title" content={title}/>
                    <meta name="twitter:description" content={title}/>
                  </Helmet> : null}
                  <Component/>
                </>
              }/>
          ))}
        </Routes>
      </Router>
    </>
  );
}
